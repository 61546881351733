<template>
  <div>
    <Navbar />
    <section
        class="hero-1-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/back-img4.jpg') + ')',
        }"
        id="home"
      >
      <div class="main-container">
        <h4 class="creators-title" @click="Back" style="cursor: pointer;"> < Shaxsiy ma'lumotlar</h4>
        <img src="../../assets/images/loading.gif" v-if="show">
        <b-overlay :v-if="!show" rounded="sm">
          <b-row>
            <b-col lg="2" sm="12">
              <img :src="`data:image/png;base64,`+ ArtistView.photobase64" style=" height: 290px; width: 100%; border-radius: 3px;">
              <h4 class="artist_fullname">{{ ArtistView.personname }}</h4>
              <p style="color: white; text-align: center;">{{ ArtistView.dateofbirth }}</p>
              <h5 style="color: white; text-align: center;">{{ ArtistView.nickname != 'null' ? ArtistView.nickname : ''}}</h5>
            </b-col>
            <b-col lg="10" sm="12">
              <b-row class="custom-scrollbar">
                <b-col lg="6" sm="12" class="mb-3">
                  <custom-label
                  :label="$t('workplace')"
                  :content="ArtistView.workplace != 'null' ? ArtistView.workplace : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="6" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('positionname')"
                    :content="ArtistView.positionname != 'null' ? ArtistView.positionname : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="3" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('teamname')"
                    :content="ArtistView.teamname != 'null' ? ArtistView.teamname : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="3" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('roleintheteam')"
                    :content="ArtistView.roleintheteam != 'null' ? ArtistView.roleintheteam : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="3" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('ignitionofcreativity')"
                    :content="ArtistView.ignitionofcreativity != 'null' ? ArtistView.ignitionofcreativity : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="3" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('genre')"
                    :content="ArtistView.genre != 'null' ? ArtistView.genre : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="4" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('licensenumber')"
                    :content="ArtistView.licensenumber == 'null' ? '-' : ArtistView.licensenumber"
                  ></custom-label>
                </b-col>
                <b-col lg="4" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('dateoflicenseissuance')"
                    :content="ArtistView.dateoflicenseissuance != 'null' ? ArtistView.dateoflicenseissuance : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="4" sm="12" class="mb-3">
                  <custom-label
                    :label="$t('licenseexpirationdate')"
                    :content="ArtistView.licenseexpirationdate != 'null' ? ArtistView.licenseexpirationdate : '-'"
                  ></custom-label>
                </b-col>
                <b-col lg="12" sm="12" class="mb-3">
                  <label style="color: #fff;">{{ $t('awardtype') }}</label>
                  <div class="cardLess text-white">
                    <p  class="omatillo">{{ArtistView.awardtype != 'null' ? ArtistView.awardtype : '-'}}</p>
                    </div>
                  <!-- <custom-label
                    :label="$t('awardtype')"
                    :content="ArtistView.awardtype != 'null' ? ArtistView.awardtype : '-'"
                  ></custom-label> -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-overlay >
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
import Navbar from '../../components/navbar.vue'
import CustomLabel from "../components/CustomLabel.vue";
import CreatorsService from '../../services/creators.sevice';
import {
  ArrowLeftIcon
} from "vue-feather-icons";
export default {
  components: { Navbar, Footer, CustomLabel, ArrowLeftIcon },
  data() {
    return {
      ArtistView: {},
      show: false,
      filter: {
        age: {},
        pinfl: ""
      }
    }
  },
  created() {
    CreatorsService.Get(this.$route.params.id)
      .then((res) => {
        this.show = true
        this.ArtistView = res.data.result;
        this.ArtistView.dateofbirth = res.data.result.dateofbirth.slice(0, -9)
        this.ArtistView.dateoflicenseissuance = res.data.result.dateoflicenseissuance != 'null' ? res.data.result.dateoflicenseissuance.slice(0, -9) : '-'
        this.ArtistView.licenseexpirationdate = res.data.result.licenseexpirationdate != 'null' ? res.data.result.licenseexpirationdate.slice(0, -9) : '-'
        this.show = false
      })
      .catch((error) => {
        this.$message(error.response);
      });
  },
  methods: {
    Back() {
      this.$router.push({
        name : "Creators",
      })
    }
  },
}
</script>

<style lang="scss">
.omatillo{
  white-space: pre;
}
.cardLess {
  background-image: linear-gradient(
    to right top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  width: 100%;
  border-radius: 10px;
  margin: auto;
  padding: 10px 40px 5px 40px;
}
</style>